import { Button } from "primereact/button";
import { formatCurrency, formatShortDate } from "../../utils/CommonUtils";
import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Sidebar } from "primereact/sidebar";
import { Message } from "primereact/message";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import useAuthenticatedRequest from "../../utils/TokenRefreshUtils";
import useValidateShift from "../../utils/ShiftUtils";
import Cookies from "js-cookie";
import { Toast } from "primereact/toast";

const SaleReturn = () => {

    const authenticatedRequest = useAuthenticatedRequest();
    const currencies = ["UAH","USD","EUR"];
    const paymentMethods = ["Готівка", "Термінал", "IBAN"];
    const navigate = useNavigate();
    const location = useLocation();
    const toast = useRef();
    const validateShift = useValidateShift();

    const [currencyRates, setCurrencyRates] = useState(null);
    const [totalSumUAH, setTotalSumUAH] = useState();
    const [existsDiscount, setExistsDiscount] = useState(false);
    const [paymentSideBarVisible, setPaymentSideBarVisible] = useState(false);
    const [payments, setPayments] = useState(null);
    const [saleAct, setSaleAct] = useState(null);
    const [unDividedSum, setUnDividedSum] = useState(0);

    useEffect(() => {

        setSaleAct(location.state?.actData);
        const actData = location.state?.actData;

        const fetchData = async () => {
            const url = `${process.env.REACT_APP_BACKEND_URL}currency/get_currencies_list/`;
            const options = { method: "GET", headers: { 'Content-Type': 'application/json' } };
            const response = await authenticatedRequest(url, options);
            const parsedResponse = await response.json();
   
            const receivedRates = parsedResponse.reduce((acc, curr) => {
               acc[curr.currency_short] = curr.currency_rate;
               return acc;
            }, {});
            
            setCurrencyRates(receivedRates);

            let amountUAH = 0;
            
            actData.count_amount.forEach(amount => {
                amountUAH += +amount.amountUAH;
            });

            actData.discount.forEach(discount => {
                amountUAH -= +discount.amount * +receivedRates[discount.currency];
            });

            setTotalSumUAH(amountUAH);
        };

        fetchData();
    
        if (!actData) {
            console.error("No actData found in location.state");
            return;
        }
    
        const hasDiscount = () => {
            if (actData?.discount) {
                return actData.discount.some(discount => discount?.amount > 0);
            }
            return false;
        };

        const formattedPayments = [];
        actData.count_amount.forEach(amount => {
            amount.paymentMethod = null;
            formattedPayments.push(amount);
        });
        
        setSaleAct(actData);
        setPayments(formattedPayments);
        setExistsDiscount(hasDiscount());
    }, [location.state, authenticatedRequest]);

    const formatReturnRequest = (values) => {
        const request = {};

        console.log(values);

        request.cagent = values.cagent;
        request.division = values.division;
        request.act_date = formatShortDate(new Date());
        request.pay_due_date = formatShortDate(new Date());
        request.act_number_autogenerate = true;
        request.shift_id = Cookies.get('ShiftId');
        if(values.unidentified_products !== null && values.unidentified_products.length > 0){
            values.unidentified_products.forEach(product => {
                product.return_sale_act_id = values.id;
            }); 
        }
        request.unidentified_products = values.unidentified_products;

        if(values.identified_products !== null && values.identified_products.length > 0){
            values.identified_products.forEach(product => {
                product.return_sale_act_id = values.id;
            }); 
        }
        request.identified_products = values.identified_products;

        console.log(request);

        return request;
    }

    const createReturn = async (request) => {

        const url = `${process.env.REACT_APP_BACKEND_URL}client_return_acts/create/`;
        const options = {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(request)
        };
        const saleResponse = await authenticatedRequest(url, options);

        if(saleResponse.status === 201){
            const parsedResponse = await saleResponse.json();
            console.log(parsedResponse);
            return parsedResponse.id;
        }

        return null;
    }

    const finalizeReturn = async (id) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}client_return_acts/make_finalized/${id}/`;
        const options = {
            method: "GET",
            headers: { 'Content-Type': 'application/json' }
        };
        const finalizeResponse = await authenticatedRequest(url, options);

        return finalizeResponse;
    }

    const createPayment = async (paymentRequest) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}accounting_payments/create/`;
        const options = {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(paymentRequest)
        };
        const paymentResponse = await authenticatedRequest(url, options);
        if(paymentResponse.status === 201){
            return true;
        }

        return null;
    }

    const formik = useFormik({
        initialValues: {
            saleData: saleAct
        },
        enableReinitialize: true,
        validate: values => {
            const errors = {};

            return errors;
        },
        onSubmit: values => {

            const performReturn = async () => {

                const isShiftValid = await validateShift();

                if (!isShiftValid) {
                    const toastMessage = { severity: "error", summary: "Помилка зміни", detail: "Зміна закрита", life: 3000 };
                    navigate('/shift', { state: { toast: toastMessage } });
                }
                else {
                    const returnRequest = formatReturnRequest(values.saleData);

                    const returnResponse =  await createReturn(returnRequest);

                    if (returnResponse !== null) {

                        const finalizeResponse = await finalizeReturn(returnResponse);

                        if (finalizeResponse.status === 200) {

                            let paymentRequest = [];

                            payments.forEach(payment => {

                                let paymentType = "CASH";
                                if (payment.paymentMethod === "Термінал") {
                                    paymentType = "CARD";
                                }
                                else if (payment.paymentMethod === "IBAN") {
                                    paymentType = "IBAN";
                                }

                                const singlePaymentRequest = {
                                    act_id: returnResponse.id,
                                    date: formatShortDate(Date.now()),
                                    sum: payment.sum,
                                    currency: payment.currency,
                                    type: paymentType,
                                    method: "INCOME",
                                    cagent_id: values.client != null ? values.client.id : null,
                                    shift_id: Cookies.get("ShiftId")
                                }

                                paymentRequest.push(singlePaymentRequest);
                            });

                            const paymentResponse = createPayment(paymentRequest);

                            if (paymentResponse !== null) {
                                const toastMessage = { severity: "success", summary: 'Оплата', detail: 'Оплата пройшла успішно', life: 2000 };
                                navigate('/shift', { state: { toast: toastMessage } });
                            }
                            else {
                                toast.current.show({ severity: "error", summary: "Помилка продажу", detail: "Помилка створення платежу, код помилки:" + finalizeResponse.status, life: 2000 });
                            }
                        }
                        else {
                            toast.current.show({ severity: "error", summary: "Помилка продажу", detail: "Помилка фіналізації акту, код помилки:" + finalizeResponse.status, life: 3000 });
                        }
                    }
                    else {
                        toast.current.show({ severity: "error", summary: "Помилка продажу", detail: "Помилка створення акту", life: 3000 });
                    }
                }
            }
            performReturn();
        }
    });

    useEffect(() => {
        let convertedAmount = 0;
  
        if (payments !== null && currencyRates !== null) {
            payments.forEach(payment => {
                convertedAmount += payment.amount * currencyRates[payment.currency];
            });
        }

        setUnDividedSum(totalSumUAH - convertedAmount);
  
     }, [totalSumUAH, currencyRates, payments]);

    const saveDraft = () => {

    }

    const handlePaymentsAmountChange = (index, newAmount) => {
        const newPayments = [...payments];
        newPayments[index].amount = newAmount;
        setPayments(newPayments);
     };
  
     const handlePaymentsCurrencyChange = (index, newCurrency) => {
        const newPayments = [...payments];
        newPayments[index].currency = newCurrency;
        setPayments(newPayments);
     };

    const handlePaymentMethodChange = (index, method) => {
        const newPayments = [...payments];
        newPayments[index].paymentMethod = method;
        setPayments(newPayments);
    }

    const deletePayment = (index) => {
        const newPayments = [...payments];
        newPayments.splice(index, 1);
        setPayments(newPayments);
    };

    const addPaymentToFormik = () => {
        const newPayment = [
           ...payments,
           { currency: "UAH", amount: 0.00, paymentMethod: null }
        ];
        setPayments(newPayment);
     };

    const getDiscountValue = () => {
        let discount = "";
        formik.values?.discount?.forEach(discount => {
            if(discount.sum > 0){
                discount += `${discount.sum}${discount.currency}`
            }
        });

        if(discount !== ""){
            return discount;
        }
        else{
            return "Без знижки";
        }
    }

    const clientButtonName = `Клієнт ${formik.values.saleData?.cagentName ? ` (${formik.values.saleData?.cagentName})` : "(Клієнта не вказано)"}`;

    console.log(payments);

    return (<>
    <Toast ref={toast}/>
    <div className='grid mt-2'>
        <div className="col-8 col-offset-2">
            <div className="surface-section">
                <div className="text-center">
                    <div className="font-medium text-xl text-900 mb-3">Cума до повернення</div>
                </div>
                <ul className="list-none p-0 m-0">
                    {formik.values.saleData !== null && formik.values?.saleData?.count_amount.map((payment, index) => (
                        <li key={index} className="flex flex-wrap justify-content-between align-items-center py-2 px-2 border-top-1 surface-border">
                            <div className="text-500 w-6 md:w-2 font-medium">{payment.currency}</div>
                            <div className="w-6 md:w-2 font-medium flex item-center justify-content-end ">
                                <span className='mr-3 mt-2 text-800 text-large'>{formatCurrency(payment.amount, payment.currency)}</span>
                            </div>
                        </li>
                    ))}
                    <li key="totalUAH" className="flex flex-wrap justify-content-between align-items-center py-2 px-2 border-top-1 surface-border">
                        <div className="text-500 font-medium">Еквівалентна сума в гривні</div>
                        <div className="w-6 md:w-2 font-medium flex item-center justify-content-end ">
                            <span className='mr-3 mt-2 text-800 text-large'>{formatCurrency(formik.values.saleData?.amountUAH, "UAH")}</span>
                        </div>
                    </li>
                </ul>
            </div>
            {existsDiscount && (
                <div className="surface-section">
                    <div className="text-center">
                        <div className="font-medium text-xl text-900">Знижка</div>
                    </div>
                    <ul className="list-none p-0 m-0">
                        {formik.values.saleData.discount.map((discount, index) => (discount.amount > 0 &&
                            <li key={`discount${index}`} className="flex flex-wrap justify-content-between align-items-center py-2 px-2 border-top-1 surface-border">
                                <div className="text-500 w-6 md:w-2 font-medium">{discount.currency}</div>
                                <div className="w-6 md:w-2 font-medium flex text-500 justify-content-end">
                                    {formatCurrency(discount.amount, discount.currency)}
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
            <div className='grid'>
                <div className='col-12 mt-2'>
                    <Button className="pageButton editButton justify-content-center font-bold" severity="info">{clientButtonName}</Button>
                </div>
            </div>
            <div className='grid'>
                {formik.values.isEdit && <div className='col-6 sm:col-6'>
                    <Button className="pr-1 pl-1 pageButton editButton responsiveButton justify-content-center font-bold" severity="info" onClick={() =>  console.log("Видалення")}>Видалити</Button>
                </div>}
                <div className={formik.values.isEdit ? "col-6 sm:col-6" : "col-12 sm:col-12"}>
                    <Button className="pr-1 pl-1 pageButton editButton responsiveButton justify-content-center font-bold" severity="info" onClick={() => setPaymentSideBarVisible(true)}>Зафіксувати повернення</Button>
                </div>
            </div>
            <div className='grid'>
                <div className='col-12'>
                    <Button className="pr-1 pl-1 pageButton editButton justify-content-center font-bold" severity="info" onClick={() => saveDraft()}>Зберегти і вийти</Button>
                </div>
            </div>
        </div>
    </div>
    <Sidebar className="responsive-sidebar" header="Оплата товару" visible={paymentSideBarVisible} position="right" onHide={() => setPaymentSideBarVisible(false)}>
         <form onSubmit={formik.handleSubmit}>
            {payments?.length === 0 && (
                <div className="mb-2">
                <Message className='sideBarMessage' severity="error" text="Додайте валюту і спосіб оплати"/>
                </div>
            )}
            {payments?.map((currencyAmount, index) => (
               <div className="grid py-3" key={index}>
                  <div className="col-10 p-inputgroup py-0 flex-1">
                     <span className="currencySpan p-inputgroup-addon">
                        <Dropdown
                           className="currencyDropDown"
                           value={currencyAmount.currency}
                           onChange={e => handlePaymentsCurrencyChange(index, e.value)}
                           options={currencies.map(currency => ({ label: currency, value: currency }))}
                        />
                     </span>
                     <InputText
                        name={`currencyValuePair[${index}].amount`}
                        value={currencyAmount.amount}
                        onChange={e => handlePaymentsAmountChange(index, e.target.value)}
                     />
                  </div>
                  <div className="col-2">
                     <Button className="noStyleButton" icon="pi pi-trash" severity="danger" type='button' text onClick={() => deletePayment(index)} />
                  </div>
                  <div className="col-10 py-0">
                     <Dropdown
                        className={formik.errors.payments && formik.errors.payments[index] && formik.touched.payments && formik.touched.payments[index] ? 'paymentTypeDropDown p-invalid' : 'paymentTypeDropDown'}
                        value={currencyAmount.paymentMethod}
                        onChange={e => handlePaymentMethodChange(index, e.value)}
                        options={paymentMethods.map(method => ({ label: method, value: method }))}
                        placeholder="Спосіб оплати"
                     />
                     {formik.errors.payments && formik.errors.payments[index] && formik.touched.payments && formik.touched.payments[index] && (
                        <small className="errorSmall">{formik.errors.payments[index].paymentMethod}</small>
                     )}
                  </div>
               </div>
            ))}
            <div className='mb-2'>
               <Message className='sideBarMessage' severity={"info"} text={`${getDiscountValue()}`} />
               <Message className='sideBarMessage' severity={unDividedSum > 0 ? "warn" : "success"} text={`Нерозподілена сума: ${unDividedSum} грн.`} />
            </div>
            <div>
               <Button label="+ Додати валюту" severity="info" type="button" className="mainPageButton editButton" onClick={addPaymentToFormik} />
               {payments?.length !== 0 &&<Button label="Оплатити" severity="success" type="submit" className="mainPageButton addButton mt-1" />}
            </div>
         </form>
      </Sidebar>
    </>)
}

export default SaleReturn;